<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('admin.table.settings.empty_text')"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(id)="data">
          {{ `#${data.item.id}` }}
        </template>

        <template #cell(status)="data">
          {{ $t(`general.statuses.${data.item.status}`) }}
        </template>

        <template #cell(type)="data">
          {{ $t(`reviews.types.${data.item.type}`) }}
        </template>

        <template #cell(description)="data">
          {{ data.item.short_description }}
        </template>

        <template #cell(reviewable)="data">
          {{ data.item.reviewable ? data.item.reviewable.title : '--deleted--' }}
        </template>

        <template #cell(reply)="data">
          {{ data.item.children.length ? $t('general.yes') : $t('general.no') }}
        </template>

        <template #cell(rating)="data">
          {{ data.item.type === 'review' ? data.item.rating : '-' }}
        </template>

        <template #cell(actions)="data">
          <div class="d-flex">
            <router-link
              v-if="$ability.can('show', 'reviews')"
              :to="{ name: 'reviews-show', params: { id: data.item.id } }"
              class="btn btn-primary btn-sm mr-1"
            >
              <feather-icon
                icon="EyeIcon"
                size="16"
                class="align-middle"
              />
            </router-link>
            <span
              v-if="$ability.can('destroy', 'reviews')"
              class="btn btn-danger btn-sm"
              @click="destroy(data.item.id)"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
                class="align-middle"
              />
            </span>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('admin.table.settings.showing') }} {{ dataMeta.from }} {{ $t('admin.table.settings.to') }} {{ dataMeta.to }} {{ $t('admin.table.settings.of') }} {{ dataMeta.of }} {{ $t('admin.table.settings.entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import SweetAlertMixin from '@mixins/SweetAlertMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useList from './useList'
import storeModule from './storeModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
  },
  mixins: [SweetAlertMixin],
  data() {
    return {
      tableColumns: [
        { key: 'id', label: this.$t('admin.table.fields.id'), sortable: false },
        { key: 'reply', label: this.$t('reviews.reply_on_review'), sortable: false },
        { key: 'status', label: this.$t('admin.table.fields.status'), sortable: false },
        { key: 'type', label: this.$t('admin.table.fields.type'), sortable: false },
        { key: 'rating', label: this.$t('admin.table.fields.rating'), sortable: false },
        { key: 'reviewable', label: this.$t('admin.table.fields.reviewable'), sortable: false },
        { key: 'description', label: this.$t('admin.table.fields.description'), sortable: false },
        { key: 'actions', label: this.$t('admin.table.fields.actions') },
      ],
    }
  },
  methods: {
    destroy(id) {
      this.confirm(() => {
        this.$http.delete(`/api/admin/reviews/${id}`)
          .then(() => this.refetchData())
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      })
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'reviews'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      statusFilter,
    } = useList()

    return {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
